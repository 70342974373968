import React from "react";
import { observer } from "mobx-react";

import List from "../../components/List";

import AuthenticationStore from "../../stores/AuthenticationStore";
import Stores from "../../stores/Stores";
import UIStore from "../../stores/UIStore";

import Edit from "./Edit";
import SearchField from "../../components/SearchField";
import SortPanel from "../../components/SortPanel";
import { baseUrl } from "../../utils/Client";

const store = Stores.load("banners", "createdDate");

class Banners extends React.Component {
  componentDidMount() {
    UIStore.init("Banners", store);
  }

  handleDelete = () => {
    let count = store.selected.length;

    UIStore.showConfirmDialog(
      `Deseja remover ${count} banners?`,
      "Remover Banners"
    ).then((b) => {
      if (b) {
        store
          .delete()
          .then(() => store.query())
          .catch(() => alert("Erro!"));
      }
    });
  };

  handleFilter = () => {
    store.page = 0;
    store.searchAll = !store.searchAll;
    store.query();
  };

  render() {
    let toggleFilters = (
      <ul className="filters-list">
        <li>
          <div
            className={!store.searchAll ? "activated" : ""}
            onClick={this.handleFilter}
          >
            Liberados
          </div>
        </li>
        <li>
          <div
            className={store.searchAll ? "activated" : ""}
            onClick={this.handleFilter}
          >
            Todos
          </div>
        </li>
      </ul>
    );

    let canEdit;
    if (AuthenticationStore.me.role === "MANAGER") {
      canEdit = false;
    } else {
      canEdit = true;
    }

    return (
      <>
        <List
          store={store}
          data={store.list.map((i) => ({
            item: i,
            content: (
              <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                <img 
                  src={`${baseUrl}/img/banner/${i.id}/246/135?count=${i.imageCount}`}
                  alt="banner"
                  height={135}
                  width={246}
                />
                <div style={{ marginLeft: 10 }}>
                  Adicionado em:{" "}
                  {i.createdDate ? i.createdDate.formatTimeStamp() : ""}
                  <br />
                  <a href={i.url} target="_BLANK" rel="noopener noreferrer">{i.url}</a>
                  <br />
                  Visualizações:{" "}
                  {i.visualizations}
                  <br />
                  Cliques:{" "}
                  {i.clicks}
                  <br />
                  Ordem:{" "}
                  {i.orderIndex}
                </div>
              </div>
            ),
          }))}
          status={store.list.map((i) => ({
            id: i.id,
            label: i.enabled ? "Liberado" : "Desativado",
            class: i.enabled ? "finished-status" : "canceled-status",
          }))}
          rightPanel={
            <>
              <SortPanel
                store={store}
                filters={{
                  createdDate: "Data de Adição",
                  visualizations: "Total de Visualizações",
                  clicks: "Total de cliques",
                }}
              />
              <SearchField store={store} />
            </>
          }
          leftPanel={toggleFilters}
          onAdd={
            canEdit
              ? () =>
                  store.new({
                    image: "",
                    url: "",
                    orderIndex: 0,
                  })
              : null
          }
          onEdit={canEdit ? () => store.open() : null}
          onDelete={canEdit ? () => this.handleDelete() : null}
        />

        <Edit onSave={() => AuthenticationStore.testAuthenticated()} />
      </>
    );
  }
}

export default observer(Banners);
