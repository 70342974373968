import { observer } from "mobx-react";
import React from "react";
import { InputAdornment } from "@material-ui/core";
import {
  Delete,
  EmojiEvents, ExpandLess, ExpandMore,
} from "@material-ui/icons";
import TextField from "../../components/TextField";
import SelectField from "../../components/SelectField";


class PrizeCard extends React.Component {
  state = {
    open: false
  }

  componentDidMount() {
    
  }

  render() {
    const { open } = this.state;
    const { 
      prize, 
      errors, 
      onChange, 
      onDelete,
    } = this.props;
    

    return (
      <div style={styles.card}>
        <div style={styles.delete}>
          <div styles={{ flex: 1 }}/>
          <div 
            style={styles.deleteBtn}
            onClick={onDelete}
          >
            <Delete />
          </div>
        </div>
        { open ? (
            <>
              <SelectField
                errors={errors}
                field="ranking"
                item={prize}
                label="&nbsp; Ranking da premiação"
                list={[
                  { label: "Primeiro lugar", value: 1 },
                  { label: "Segundo lugar", value: 2 },
                  { label: "Terceiro lugar", value: 3},
                ]}
                onChange={(e) => onChange("ranking", e.target.value)}
                style={styles.inputOutline}
                variant="outlined"
              />
              <TextField
                errors={errors}
                field="description"
                item={prize}
                label="Descrição dos prêmios"
                placeholder="Descrição dos prêmios"
                rows={5}
                onChange={(e) => onChange("description", e.target.value)}
                onBlur={() => {
                  if(prize.description.length > 0 && prize.description.charAt(0) !== "•"){
                    var items = prize.description.split("\n");
                    items = items.filter(i => i.length > 0);
                    onChange("description", `•    ${items.join("\n•    ")}`);
                  }
                }}
                style={styles.inputOutline}
                variant="outlined"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <EmojiEvents color="primary" />
                    </InputAdornment>
                  ),
                }}
              />
              <div style={styles.hint}>
                Descreva os prêmios. Cada linha será visualizada no app como um item em uma bullet list.
              </div>
              <TextField
                errors={errors}
                field="descriptionFr"
                item={prize}
                label="Descrição dos prêmios (Francês)"
                placeholder="Descrição dos prêmios (Francês)"
                rows={5}
                onChange={(e) => onChange("descriptionFr", e.target.value)}
                onBlur={() => {
                  if(prize.description.length > 0 && prize.description.charAt(0) !== "•"){
                    var items = prize.description.split("\n");
                    items = items.filter(i => i.length > 0);
                    onChange("descriptionFr", `•    ${items.join("\n•    ")}`);
                  }
                }}
                style={styles.inputOutline}
                variant="outlined"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <EmojiEvents color="primary" />
                    </InputAdornment>
                  ),
                }}
              />
              <div
                onClick={() => this.setState({ open: false })}
                style={styles.showMore}
              >
                <ExpandLess /> 
              </div>
            </>
        ) : (
          <>
            <div style={styles.cardValue}>
              {!!prize.ranking ? `${prize.ranking}º lugar` : "Sem ranking"}
            </div>
            <div style={styles.cardValue}>
              { !!prize.description ? prize.description : "Sem descrição" }
            </div>
            <div
              onClick={() => this.setState({ open: true })}
              style={styles.showMore}
            >
              <ExpandMore /> 
            </div>
          </>
          )
        }
      </div>
    );
  }
}

export default observer(PrizeCard);

const styles = {
  card: {
    backgroundColor: "#F2F2F2",
    borderRadius: "8px",
    display: "flex",
    flexDirection: "column",
    paddingTop: "20px",
    paddingLeft: "20px",
    paddingRight: "20px",
    minWidth: "300px",
  },
  inputOutline: {
    marginBottom: "20px",
  },
  cardValue: {
    fontWeight: "700",
    fontSize: "14px",
  },
  showMore: {
    cursor: "pointer",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    marginBottom: "12px",
  },
  delete: {
    position: "relative",
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  deleteBtn: {
    cursor: "pointer",
    color: "#F00",
  },
  hint: {
    fontSize: 12,
    color: "#666",
    marginTop: -20,
    marginBottom: 20,
  }
};
