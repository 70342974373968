import React from "react";
import { observer } from "mobx-react";
import List from "../../components/List";
import { InputBase } from "@material-ui/core";
import UIStore from "../../stores/UIStore";
import Stores from "../../stores/Stores";
import DateRange from "../../components/DateRange";
import SelectPanel from "../../components/SelectPanel";
import { baseUrl } from "../../utils/Client";
import { Bar, BarChart, CartesianGrid, LabelList, Line, LineChart, Tooltip, XAxis, YAxis } from "recharts";
import SelectUserModal from "./SelectUserModal";
import TuneIcon from "@material-ui/icons/Tune";
import dayjs from "dayjs";


const CATEGORIES = [
  { value: "TOP_FERMENTABLES", label: "Fermentáveis mais usados", isList: true },
  { value: "TOP_HOPS", label: "Lúpulos mais usados", isList: true },
  { value: "TOP_YEASTS", label: "Leveduras mais usados", isList: true },
  { value: "TOP_VOLUMES", label: "Litragens mais usadas em receitas", isGraph: true, hasStep: true, isLine: false },
  { value: "TOP_STYLES", label: "Estilos mais usados em receitas", isList: true },
  { value: "TOP_BREW_STYLES", label: "Estilos mais produzidos", isList: true },
  { 
    value: "RECIPE_CREATION_HISTORY", 
    label: "Frequência de criação de receitas", 
    isGraph: true, 
    hasStep: false, 
    isLine: true, 
    showTotal: true,
    selectVisibilityCompleteness: true,
  },
  { value: "BREW_HISTORY", label: "Frequência de produção", isGraph: true, hasStep: false, isLine: true },
  { value: "TOP_TEXT_CONTENT", label: "Dicas mais lidas", isList: true },
  { value: "TOP_LIKED_RECIPES", label: "Receitas mais favoritadas", isList: true },
  { value: "TOP_DISPLAYED_PROFILES", label: "Perfis mais visualizados", isList: true },
  { value: "TOTAL_USERS", label: "Total de usuários", isGraph: true, hasStep: false, isLine: true, showTotal: true, hideUserModal: true },
];
const PRODUCER_TYPES = [
  { value: null, label: "Todas" },
  { value: "BREWER", label: "Cervejeiro" },
  { value: "BREWERY", label: "Cervejaria" },
];
const DATES = [
  { 
    label: "7 dias", 
    initialDate: dayjs().add(-7, "days").format("YYYY-MM-DD"), 
    finalDate: dayjs().format("YYYY-MM-DD") 
  },
  { 
    label: "15 dias", 
    initialDate: dayjs().add(-15, "days").format("YYYY-MM-DD"), 
    finalDate: dayjs().format("YYYY-MM-DD") 
  },
  { 
    label: "30 dias", 
    initialDate: dayjs().add(-30, "days").format("YYYY-MM-DD"),
    finalDate: dayjs().format("YYYY-MM-DD")
  },
  { 
    label: "Todos", 
    initialDate: null,
    finalDate: null
  },
];
const VISIBILITY = [
  { value: "PUBLIC", label: "Público" },
  { value: "PRIVATE", label: "Privado" },
  { value: null, label: "Todos" },
];
const COMPLETENESS = [
  { value: "COMPLETE", label: "Completo" },
  { value: "INCOMPLETE", label: "Incompleto" },
  { value: null, label: "Todos" },
];

const reportsStore = Stores.load("reports");
reportsStore.initialDate = DATES[0].initialDate;
reportsStore.category = CATEGORIES[0];
reportsStore.visibility = VISIBILITY[2];
reportsStore.completeness = COMPLETENESS[2];
reportsStore.size = 10;
reportsStore.chartWidth = window.innerWidth * 0.75;
reportsStore.chartHeight = window.innerWidth * 0.50;
reportsStore.step = 100;

class Reports extends React.Component {
  state = {
  };
  timer = null;

  componentDidMount(){
    window.addEventListener("resize", this.onResize);
    UIStore.init("Reports", reportsStore);
    reportsStore.query();
  }

  onResize = () => {
    reportsStore.chartWidth = window.innerWidth * 0.75;
    reportsStore.chartHeight = reportsStore.category.isGraph ? Math.abs(window.innerHeight*0.7) : (reportsStore.count * 18) + 100;
  }

  renderRow = (item) => {
    var selected = {};
    var title = "";
    var valueLabel = "";
    var imgUrl = null;
    
    const userIsSelected = !!reportsStore.selectedUser;
    switch(reportsStore.category.value){
      case "TOP_FERMENTABLES":
        selected = item.fermentable;
        title = selected.title;
        valueLabel = `Usado em ${item.value} receita${item.value > 1 ? "s" : ""}`;
        break;
      case "TOP_HOPS":
        selected = item.hop;
        title = selected.title;
        valueLabel = `Usado em ${item.value} receita${item.value > 1 ? "s" : ""}`;
        break;
      case "TOP_YEASTS":
        selected = item.yeast;
        title = selected.title;
        valueLabel = `Usado em ${item.value} receita${item.value > 1 ? "s" : ""}`;
        break;
      case "TOP_STYLES":
        selected = item.beerStyle;
        title = selected?.name;
        valueLabel = `Usado em ${item.value} receita${item.value > 1 ? "s" : ""}`;
        break;
      case "TOP_BREW_STYLES":
        selected = item.beerStyle;
        title = selected.name;
        valueLabel = `Usado em ${item.value} produç${item.value > 1 ? "ão" : "ões"}`;
        break;
      case "TOP_TEXT_CONTENT":
        selected = item.content;
        title = selected.title;
        valueLabel = userIsSelected ? "" : `Lido por ${item.value} usuário${item.value > 1 ? "s" : ""}`;
        break;
      case "TOP_LIKED_RECIPES":
        selected = item.recipe;
        title = selected.name;
        valueLabel = userIsSelected ? "" : `Favoritado por ${item.value} usuário${item.value > 1 ? "s" : ""}`;
        break;
      case "TOP_DISPLAYED_PROFILES":
        selected = item.user;
        title = selected.name;
        valueLabel = userIsSelected ? "" : `Perfil visualizado por ${item.value} usuário${item.value > 1 ? "s" : ""}`;
        imgUrl = `${baseUrl}/img/avatar/${selected.id}/200/200?count=${selected.avatarCount}`
        break;
      default:
        return <></>;
    }

    return(
      {
        item: item,
        title: "",
        content: (
          <div style={{ display: "flex", flexDirection: "row", gap: 10 }}>
            <div>
              { !!imgUrl && <img src={imgUrl} style={{ width: 100, height: 100 }} alt="avatar" /> }
            </div>
            <div style={{ display: "flex", flexDirection: "column", gap: 10, justifyContent: "center" }}>
              <div style={{ fontWeight: "bold", fontSize: 16, color: "#000" }}>{title}</div>
              <div>
                { !!selected?.author && `Criado por ${selected.author.name} (${selected.author.email})` }
              </div>
              <div>
                { valueLabel }
              </div>
            </div>
          </div>
        ),
      }
    )
  }

  formatAxisDate = (date) => {
    const d = dayjs(date);
    return d.format("DD/MM/YYYY");
  }

  render() {
    const { chartWidth, chartHeight, selectedUser, showSelectUserModal } = reportsStore;

    return (
      <>
        <SelectUserModal 
          open={showSelectUserModal}
          onClose={() => {
            reportsStore.showSelectUserModal = false;
          }}
          onSelect={(item) => {
            reportsStore.selectedUser = item;
            reportsStore.showSelectUserModal = false;
            reportsStore.query();
          }}
        />
        <div style={{ flex: 1 }}>
          <div>
            <List
              data={reportsStore.category.isList ? reportsStore.list.map((i) => this.renderRow(i)) : []}
              status={[]}
              leftPanel={
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <SelectPanel
                    store={reportsStore}
                    options={CATEGORIES}
                    label="Categoria"
                    getLabel={(item) => item.label}
                    entity="category"
                    hideNull={true}
                  />
                  { !reportsStore.category.hideUserModal &&
                    <div
                      style={{
                        width: "100%",
                        margin: "2px 10px 0 0",
                        lineHeight: "35px",
                        cursor: "pointer",
                        display: "flex",
                        alignItems: "center",
                      }}
                      onClick={() => {
                        reportsStore.showSelectUserModal = true;
                      }}
                    >
                      <strong>Usuário:</strong> <TuneIcon style={{ marginLeft: "5px" }} />{" "}
                      { !!selectedUser ? selectedUser.name : "Todos"}
                    </div>
                  }
                  { !reportsStore.category.hideProducerType &&
                    <SelectPanel
                      store={reportsStore}
                      options={PRODUCER_TYPES}
                      label="Tipo de conta"
                      getLabel={(item) => item.label}
                      entity="producerType"
                      hideNull={true}
                    />
                  }
                  { reportsStore.category.selectVisibilityCompleteness &&
                    <>
                      <SelectPanel
                        store={reportsStore}
                        options={VISIBILITY}
                        label="Visibilidade"
                        getLabel={(item) => item?.label}
                        entity="visibility"
                      /> 
                      <SelectPanel
                        store={reportsStore}
                        options={COMPLETENESS}
                        label="Completude"
                        getLabel={(item) => item?.label}
                        entity="completeness"
                      />                    
                    </>
                  }
                </div>
              }
              rightPanel={
                <div style={styles.col}>
                  <div>
                    <DateRange
                      store={reportsStore}
                      customDates={DATES}
                      initialLabel={DATES[0].label}
                      label="Período" 
                    />
                  </div>
                  { reportsStore.category.hasStep &&
                    <div style={styles.row}>
                      <div>Tamanho do passo:</div>
                      <InputBase
                        placeholder="Tamanho do passo"
                        onChange={(e) => {
                          reportsStore.step = e.target.value.replace(new RegExp("[^0-9]", "g"), "");
                          clearTimeout(this.timer);
                          this.timer = setTimeout(() => reportsStore.query(), 700);
                        }}
                        value={reportsStore.step}
                        disabled={reportsStore.loading}
                      />
                    </div>
                  }
                  { reportsStore.category.showTotal &&
                    <div style={styles.row}>
                      <div>{`Total: ${reportsStore.total}`}</div>
                    </div>
                  }
                </div>
              }
            />
            { reportsStore.category.isGraph &&
              <div style={{ display: "flex", flexDirection: "column", alignItems: "center", marginTop: -80 }}>
                { (reportsStore.list.length > 0 && reportsStore.category.isLine) ?
                  <LineChart
                    width={chartWidth}
                    height={chartHeight}
                    data={reportsStore.list}
                    margin={{ right: 0, left: 0, top: 10, bottom: 80 }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis 
                      type="category" 
                      dataKey="date" 
                      tickFormatter={this.formatAxisDate}
                    />
                    <YAxis type="number" dataKey="value" />
                    <Tooltip 
                      itemStyle={{ color: "black" }} 
                      labelStyle={{ color: "black" }} 
                      labelFormatter={this.formatAxisDate}
                    />
                    <Line dataKey="value" fill="#8884d8" dot={false} />
                    <Tooltip />
                  </LineChart>
                  : (reportsStore.list.length > 0) &&
                  <BarChart
                    layout="vertical"
                    width={chartWidth}
                    height={chartHeight}
                    data={reportsStore.list}
                    margin={{ right: 0, left: 0, top: 10, bottom: 80 }}
                  >
                    <XAxis type="number" />
                    <YAxis 
                      type="category" 
                      dataKey="name" 
                      cursor="pointer"
                      interval={0}
                      tick={{ fontSize: 14 }}
                      width={200}
                      tickFormatter={(v, index) => v.length > 20 ? v.slice(0, 20) + "..." : v}
                    />
                    <Tooltip itemStyle={{ color: "black" }} labelStyle={{ color: "black" }}/>
                    <Bar
                      dataKey="value" 
                      fill="#8884d8"
                      cursor="pointer"
                    >
                      <LabelList
                        dataKey="value" 
                        position="right" 
                        style={{ fontSize: 14 }}
                      />
                    </Bar>
                    <Tooltip />
                  </BarChart>
                }
              </div>
            }
          </div>
        </div>
      </>
    );
  }
}

export default observer(Reports);

const styles = {
  value: {

  },
  row: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: 10,
  },
  col: {
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
    gap: 10,
  },
};