import { observer } from "mobx-react";
import React from "react";
import { Button, InputAdornment } from "@material-ui/core";
import {
  AddCircle,
  CalendarToday,
  EmojiEvents,
  Gavel,
} from "@material-ui/icons";
import TextField from "../../components/TextField";
import EditDialog from "../../components/EditDialog";
import CheckBox from "../../components/CheckBox";
import Stores from "../../stores/Stores";
import UIStore from "../../stores/UIStore";
import { AT_LEAST_ONE, MORE_THAN_ZERO, REQUIRED, hasErrors, validate } from "../../utils/Validation";
import ChallengeCard from "./ChallengeCard";
import PrizeCard from "./PrizeCard";


const store = Stores.load("challenge-groups");

class ChallengeGroupsEdit extends React.Component {
  constructor(props){
    super(props);

    this.state = {
      challengeErrors: [],
      prizeErrors: [],
    };
  }

  componentDidMount() {
    store.rules = {
      name: REQUIRED,
      startDate: REQUIRED,
      validUntil: REQUIRED,
      challenges: [REQUIRED, AT_LEAST_ONE],
      challengePrizes: [REQUIRED, AT_LEAST_ONE],
      rules: REQUIRED,
      rulesFr: REQUIRED,
    };
  }

  save = async () => {
    try{
      var hasError = false;
      store.resetErrors();

      const challengeRules = {
        name: REQUIRED, 
        type: REQUIRED, 
        points: [REQUIRED, MORE_THAN_ZERO], 
        timesAchievable: 1,
      };
      const prizeRules = {
        ranking: REQUIRED,
        description: REQUIRED,
      }
      const challengeErrors = [];
      const prizeErrors = [];
      
      for(const challenge of this.item.challenges){
        if(challenge.alwaysAchievable){
          delete challengeRules.timesAchievable; 
        } else{
          challengeRules.timesAchievable = [REQUIRED, MORE_THAN_ZERO];
        }
        const error = validate(challengeRules, challenge);
        challengeErrors.push(error);
        hasError = hasError || hasErrors(error);
      }
      for(const prize of this.item.challengePrizes){
        const error = validate(prizeRules, prize);
        const sameRanking = this.item.challengePrizes.filter(p => p.ranking === prize.ranking);
        if(sameRanking.length > 1){
          error["ranking"] = "Este ranking já foi selecionado";
        }
        prizeErrors.push(error);
        hasError = hasError || hasErrors(error);
      }
      this.setState({ challengeErrors, prizeErrors });

      if(!hasError){
        await store.save(this.props.onSave);
        UIStore.redo(store);
      } else{
        UIStore.saveError("Erro ao salvar");
      }
    }catch(e){
      console.log(e.message ?? e);
      UIStore.saveError(e);
    }
  }

  render() {
    const { challengeTypes } = this.props;
    const { challengeErrors, prizeErrors } = this.state;
    this.item = store.edit != null ? store.edit : {};
    
    return (
      <EditDialog
        title={this.item.id ? "Editar Desafio" : "Novo Desafio"}
        opened={store.edit != null}
        onCancel={() => (store.edit = null)}
        onSave={this.save}
      >
        <div className="rowStyles">
          <div className="columnStyles">
            <TextField
              errors={store.errors}
              field="name"
              item={this.item}
              label="Nome"
              placeholder="Nome"
              style={styles.inputOutline}
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <EmojiEvents color="primary" />
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              errors={store.errors}
              field="startDate"
              item={this.item}
              onChange={(e) => (this.item["startDate"] = e.target.value.formatDate())}
              label="Data de início"
              placeholder="DD/MM/AAAA"
              style={styles.inputOutline}
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <CalendarToday color="primary" />
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              errors={store.errors}
              field="validUntil"
              item={this.item}
              onChange={(e) => (this.item["validUntil"] = e.target.value.formatDate())}
              label="Data de finalização"
              placeholder="DD/MM/AAAA"
              style={styles.inputOutline}
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <CalendarToday color="primary" />
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              errors={store.errors}
              field="rules"
              item={this.item}
              label="Regras"
              placeholder="Digite as regras do desafio"
              rows={5}
              style={styles.inputOutline}
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Gavel color="primary" />
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              errors={store.errors}
              field="rulesFr"
              item={this.item}
              label="Regras (Francês)"
              placeholder="Digite as regras do desafio (Francês)"
              rows={5}
              style={styles.inputOutline}
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Gavel color="primary" />
                  </InputAdornment>
                ),
              }}
            />
            
            
            { !!this.item.id &&
              <div>
                <CheckBox label="Liberado?" field="enabled" item={this.item} />
              </div>
            }
          </div>
          <div className="columnStyles" style={{ marginLeft: 20, marginRight: 20 }}>
            <div style={styles.row}>
              <h3>Prêmios</h3>
              { this.item.challengePrizes?.filter(cp => cp.enabled).length < 3 &&
                <Button
                  onClick={() => {
                    if(this.item.challengePrizes.filter(cp => cp.enabled).length < 3){
                      this.item.challengePrizes.push({ 
                        description: "",
                        descriptionFr: "",
                        ranking: null, 
                        enabled: true, 
                      });
                    }
                  }}
                  style={styles.btn}
                >
                  <div style={styles.row}>
                    <AddCircle color="primary" fontSize="large" />
                    <div style={{ marginLeft: "10px" }}>Novo prêmio</div>
                  </div>
                </Button>
              }
            </div>
            <div style={styles.cardsRow}>
              { this.item?.challengePrizes?.map((prize, index) => prize.enabled &&
                <div key={`prize-${index}`}>
                  <PrizeCard 
                    prize={prize}
                    onChange={(name, value) => {
                      prize[name] = value;
                    }}
                    onDelete={() => {
                      if(!!prize.id){
                        prize.enabled = false;
                      } else{
                        this.item.challengePrizes = this.item.challengePrizes.filter(p => p !== prize);
                      }
                    }}
                    errors={!!prizeErrors[index] ? prizeErrors[index] : {}}
                  />
                </div>
              )}
            </div>
          </div>
          <div className="columnStyles">
            <div style={styles.row}>
              <h3>Pontuações</h3>
              <Button
                onClick={() => 
                  this.item.challenges.push({ 
                    name: "", 
                    nameFr: "",
                    type: null, 
                    points: "", 
                    alwaysAchievable: false, 
                    timesAchievable: 1,
                    enabled: true, 
                  })
                }
                style={styles.btn}
              >
                <div style={styles.row}>
                  <AddCircle color="primary" fontSize="large" />
                  <div style={{ marginLeft: "10px" }}>Nova pontuação</div>
                </div>
              </Button>
            </div>
            <div style={styles.cardsRow}>
              { this.item?.challenges?.map((challenge, index) => challenge.enabled &&
                <div key={`challenge-${index}`}>
                  <ChallengeCard 
                    challenge={challenge}
                    onChange={(name, value) => {
                      challenge[name] = value;
                    }}
                    onDelete={() => {
                      if(!!challenge.id){
                        challenge.enabled = false;
                      } else{
                        this.item.challenges = this.item.challenges.filter(c => c !== challenge);
                      }
                    }}
                    challengeTypes={challengeTypes}
                    errors={!!challengeErrors[index] ? challengeErrors[index] : {}}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </EditDialog>
    );
  }
}

export default observer(ChallengeGroupsEdit);

const styles = {
  inputOutline: {
    marginBottom: "20px",
  },
  row: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  btn: {
    fontSize: "12px",
    fontWeight: "700",
    color: "#ff9900",
  },
  cardsRow: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "10px",
  },
};
