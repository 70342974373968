import React from "react";
import { observer } from "mobx-react";
import List from "../../components/List";
import AuthenticationStore from "../../stores/AuthenticationStore";
import Stores from "../../stores/Stores";
import UIStore from "../../stores/UIStore";
import Edit from "./Edit";
import SearchField from "../../components/SearchField";
import SortPanel from "../../components/SortPanel";
import { baseUrl } from "../../utils/Client";


const store = Stores.load("hops-cup-farms");

class HopsCupFarms extends React.Component {
  state = {
    
  }

  componentDidMount() {
    UIStore.init("Copa Brasileira de Lúpulos (Fazendas Cadastradas)", store);
  }

  handleFilter = () => {
    store.page = 0;
    store.searchAll = !store.searchAll;
    store.query();
  };

  render() {
    const toggleFilters = (
      <ul className="filters-list">
        <li>
          <div
            className={!store.searchAll ? "activated" : ""}
            onClick={this.handleFilter}
          >
            Liberados
          </div>
        </li>
        <li>
          <div
            className={store.searchAll ? "activated" : ""}
            onClick={this.handleFilter}
          >
            Todos
          </div>
        </li>
      </ul>
    );
    const canEdit = AuthenticationStore.me.role !== "MANAGER";

    return (
      <>
        <List
          store={store}
          data={store.list.map((i) => ({
            item: i,
            title: (!!i.name ? i.name : "Sem nome"),
            content: (
              <div style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: 20 }}>
                <img 
                  src={`${baseUrl}/img/hops-cup-farm-card/${i.id}/250/125?count=${i.cardImageCount}`}
                  alt="banner"
                  style={{ borderRadius: 15 }}
                  width={250}
                  height={125}
                />
                <div>{`Posição: ${i.orderIndex}`}</div>
              </div>
            ),
          }))}
          status={store.list.map((i) => ({
            id: i.id,
            label: i.enabled ? "Liberado" : "Desativado",
            class: i.enabled ? "finished-status" : "canceled-status",
          }))}
          rightPanel={
            <>
              <SortPanel
                store={store}
                filters={{
                  name: "Nome",
                  createdDate: "Data de Adição",
                }}
              />
              <SearchField store={store} />
            </>
          }
          leftPanel={toggleFilters}
          onAdd={
            canEdit
              ? () =>
                  store.new({
                    name: "",
                    about: "",
                    history: "",
                    image: null,
                    cardImage: null,
                    historyImage: null,
                    hops: [],
                    selectedHop: null,
                    uf: "",
                    city: "",
                    orderIndex: 0,
                  })
              : null
          }
          onEdit={canEdit ? () => store.open() : null}
        />

        <Edit 
          onSave={() => AuthenticationStore.testAuthenticated()} 
        />
      </>
    );
  }
}

export default observer(HopsCupFarms);
